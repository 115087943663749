body {
  background-color: #eeeeee;
  background-repeat: repeat;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
